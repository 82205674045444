.forgot-password-container, .forgot-password-label, .forgot-password-link {
  font-weight: normal;
  margin-bottom: 0px;
}

@media (hover: hover) {
  .forgot-password-link:hover {
    color: var(--dark-gray);
    cursor: pointer;
  }
}
