.line-header {
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.line-header:before, .line-header:after {
  content: '';
  border-top: 1px solid var(--light-gray);
  margin: 0 20px 0 0;
  flex: 1 0 20px;
}

.line-header:after {
  margin: 0 0 0 20px;
}

@media all and (max-width: 480px) {
  .line-header {
    font-size: 16px;
  }
}
