.navbar * {
  color: white !important;
  font-weight: 600;
  font-size: 18px;
}

.navbar {
  background-color: var(--dark-blue);
  border: none;
  border-radius: 0px;
}

.navbar-collapse li.active a {
  color: var(--gray) !important;
  background-color: white !important;
}

@media all and (min-width: 769px) {
  .transparent {
    background-color: transparent;
  }
}

.navbar-toggle {
  background-color: white !important;
}

.navbar-right {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand img {
  height: 30px;
}

@media (hover: hover) {
  .navbar-brand img:hover {
    height: 32px;
  }
}

@media all and (max-width: 480px) {
  .navbar * {
    font-size: 16px;
  }
}
