.page {
  position: relative;
  padding-top: 20px;
}

.page-subheader {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 12px;
  font-size: 22px;
}

.modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.modal-content {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.modal-header {
  border-bottom: none;
  padding-left: 25px;
  padding-right: 25px;
}

.modal-title {
  padding-top: 15px;
  font-size: 26px;
}

.modal-body {
  padding: 0px 25px 15px 25px;
}

@media all and (min-width: 1000px) {
  .page {
    height: 95vh;
  }
}

@media all and (max-width: 480px) {
  .page-subheader {
    font-size: 18px;
  }
}

@media all and (max-width: 408px) {
  .modal-title {
    font-size: 24px;
  }
}
