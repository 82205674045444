.static-problem-page {
  margin-bottom: 50px;
}

.problem-explanation-header {
  margin-top: 40px;
}

.problem-explanation-header.low-top-margin {
  margin-top: 15px;
}

.solutions-header {
  margin-top: 35px;
}

.static-problem-page h4 {
  margin-top: 20px;
  text-decoration: underline;
}

.static-problem-page p {
  line-height: 1.7;
  margin-bottom: 0px;
}

.product-recommendations-label {
  margin-top: 7px;
  margin-bottom: 5px;
  font-style: italic;
}

.product-recommendation-cards {
  display: flex;
  flex-wrap: wrap;
}

.add-problem-link, .remove-problem-link, .make-exception-link {
  display: flex;
  justify-content: center;
}

.remove-problem-link {
  margin-top: 30px;
}

.make-exception-link {
  margin-top: 5px;
}

.add-problem-link p, .create-solution-link {
  color: var(--blue);
}

.remove-problem-link p {
  color: var(--red);
}

.make-exception-link p {
  font-style: italic;
}

.add-problem-link p:hover, .create-solution-link:hover {
  cursor: pointer;
  color: var(--dark-blue);
}

.create-solution-link {
  margin-top: 10px;
}

.remove-problem-link p:hover {
  cursor: pointer;
  color: var(--dark-red);
}

.make-exception-link p:hover {
  cursor: pointer;
  color: var(--dark-gray);
}

.solution-name {
  position: relative;
}

.fa-heart {
  color: var(--red);
  padding-left: 10px;
}

.fa-heart:hover {
  cursor: pointer;
}

.custom-solutions-container {
  margin-top: 20px;
}

.custom-solutions h4 {
  display: inline;
}

.custom-solutions-helper-text {
  margin-left: 10px;
}

.custom-solutions ul {
  padding-left: 18px;
  margin-bottom: 0px;
}

.custom-solutions ul li {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media all and (max-width: 480px) {
  .static-problem-page h3 {
    font-size: 18px;
  }

  .problem-explanation-header {
    margin-top: 30px;
  }

  .problem-explanation-header.low-top-margin {
    margin-top: 15px;
  }

  .solutions-header {
    margin-top: 22px;
  }

  .static-problem-page h4 {
    font-size: 15px;
    margin-top: 15px;
  }

  .custom-solutions-container {
    margin-top: 15px;
  }

  .product-recommendations-label {
    margin-bottom: 15px;
  }
}
