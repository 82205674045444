.snackbar-container {
  display: flex;
  justify-content: center;
  visibility: hidden;
}

.snackbar-container.visible {
  visibility: visible;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.snackbar {
  position: fixed;
  bottom: 20px;
  width: 300px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

@media all and (max-width: 480px) {
  .snackbar {
    width: 250px;
    height: 40px;
  }
}

@keyframes fadein {
  from { bottom: 0; opacity: 0; }
  to { bottom: 20px; opacity: 1; }
}

@-webkit-keyframes fadein {
  from { bottom: 0; opacity: 0; }
  to { bottom: 20px; opacity: 1; }
}

@keyframes fadeout {
  from { bottom: 20px; opacity: 1; }
  to { bottom: 0; opacity: 0; }
}

@-webkit-keyframes fadeout {
  from { bottom: 20px; opacity: 1; }
  to { bottom: 0; opacity: 0; }
}
