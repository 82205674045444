.card-container {
  display: inline-block;
  position: relative;
  margin: 15px;
  width: 23vh;
  height: 23vh;
}

.card {
  width: 90%;
  height: 90%;
  border: solid var(--light-gray) 1px;
  border-radius: 10px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.recommendation-card-container {
  width: 21vh;
  height: 21vh;
  margin-bottom: 7px;
  margin-left: 0px;
  margin-right: 25px;
}

.recommendation-card {
  width: 100%;
  height: 100%;
}

.card-selected {
  border: solid var(--blue) 1px !important;
  background-color: var(--extra-light-blue);
}

.card-without-image p {
  position: absolute;
  text-align: center;
  width: 100%;
  padding: 20px;
  color: var(--gray);
  margin-bottom: 0px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-details-link {
  margin-top: 5px;
}

.hidden-card-details-link {
  margin-top: 5px;
  visibility: hidden;
}

.card-details-link p {
  color: var(--light-blue);
  font-size: 14px;
}

.card-with-image {
  background-color: white;
  border: solid var(--gray) 1px;
}

.card-with-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  opacity: 0.4;
}

.card-with-image span {
  position: relative;
  color: var(--dark-gray);
  z-index: 2;
  text-align: center;
  width: 100%;
  padding: 20px;
  margin-bottom: 0px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

.card-with-image span p {
  font-weight: bold;
}

@media all and (max-width: 480px) {
  .card-container {
    width: 21vh;
    height: 21vh;
    margin: 5px;
    margin-bottom: 22px;
  }

  .recommendation-card-container {
    width: 19vh;
    height: 19vh;
    margin-top: 12px;
    margin-right: 20px;
    margin-bottom: 7px;
  }

  .card p {
    font-size: 13px;
  }

  .card-details-link p {
    color: var(--light-blue);
    font-size: 12px;
  }
}

@media (hover: hover) {
  .card-container:hover {
    cursor: pointer;
  }

  .card-container:hover .card-without-image {
    border: solid var(--light-blue) 1px;
  }

  .card-with-image img {
    opacity: 1;
  }

  .card-container:hover .card-with-image img {
    opacity: 0.2;
  }

  .card-with-image span {
    display: none;
  }

  .card-container:hover .card-with-image span {
    display: block;
  }

  .card-container:hover {
    visibility: visible;
  }

  .card-selected:hover {
    border: solid var(--blue) 1px !important;
  }

  .card-details-link {
    visibility: hidden;
  }

  .card-container:hover .card-details-link {
    visibility: visible;
  }

  .card-details-link p:hover {
    color: var(--blue);
    text-decoration: none;
  }
}
