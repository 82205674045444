h4.add-solution {
  font-size: 26px;
  margin-bottom: 15px;
}

.add-another-solution {
  color: var(--blue);
  margin-top: 0px;
}

.add-another-solution:hover {
  cursor: pointer;
  color: var(--dark-blue);
}

.problem-error {
  margin-top: 10px;
}

@media all and (max-width: 480px) {
  h4 {
    font-size: 15px !important;
  }
}
