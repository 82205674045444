.checklist {
  margin-bottom: 40px;
}

.checklist-page-header {
  margin-bottom: 30px;
}

.checklist-subheader {
  text-align: center;
  margin-bottom: 30px;
}

.checklist-container {
  display: flex;
  justify-content: center;
}

.checklist-categories, .checklist-subcategories {
  padding-left: 0px;
}

.checklist-category {
  font-weight: 700;
  list-style-type: none;
  margin-bottom: 45px;
}

.checklist-subcategory {
  font-weight: 600;
  list-style-type: none;
  margin-top: 15px;
}

.checklist-category a, .exception a {
  color: var(--gray);
}

.checklist-subcategory-name {
  padding-left: 5px;
}

.checklist-problems {
  padding-left: 7px;
}

.checklist-problem-container {
  display: flex;
}

.checklist-problem {
  font-weight: normal;
  list-style-type: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

.right-arrow {
  color: var(--gray);
  padding-left: 7px;
  padding-right: 7px;
}

input[type=checkbox] {
  margin-right: 10px;
  float: left;
}

.exceptions-box {
  margin-top: 50px;
  margin-bottom: 50px;
  border: solid 1px var(--light-gray);
  padding-left: 30px;
  padding-right: 30px;
}

.exceptions-header h1 {
  font-size: 30px;
}

.exceptions-container {
  display: flex;
  justify-content: center;
}

.exceptions {
  padding-left: 30px;
}

.exceptions-header {
  margin-top: 40px !important;
}

.add-custom-problem-link, .add-custom-category-link {
  color: var(--gray);
}

.add-custom-category-link {
  text-align: center;
}

.add-custom-problem-link .fa-plus {
  padding-right: 14px;
}

.fa-pencil-alt {
  padding-left: 10px;
}

.exception {
  color: var(--dark-gray);
}

.helper-notes {
  padding-left: 20px;
}

.tips {
  margin-top: 5px !important;
  font-weight: 600;
}

.exception {
  list-style-type: disc;
}

.complete-modal-title {
  font-size: 36px;
  text-align: center;
}

.return-to-checklist {
  font-size: 16px !important;
}

.no-items-yet {
  margin-bottom: 30px;
}

@media all and (max-width: 480px) {
  .exceptions-box {
    margin-bottom: 30px;
  }

  .exceptions-header h1 {
    font-size: 24px;
  }

  .return-to-checklist {
    font-size: 13px !important;
  }

  .no-items-yet {
    font-size: 18px;
  }
}

@media (hover: hover) {
  .checklist-category a:hover, .exception a:hover {
    color: var(--dark-gray);
    text-decoration: none;
  }

  .add-custom-problem-link:hover, .add-custom-category-link:hover {
    cursor: pointer;
    color: var(--dark-gray);
  }

  .fa-pencil-alt:hover {
    cursor: pointer;
  }
}

@media all and (min-width: 700px) {
  .checklist-category {
    -webkit-column-break-inside: avoid;
  }
}

@media all and (min-width: 1100px) {
  .two-columns {
    column-count: 2;
    column-gap: 200px;
    column-rule: 1px solid var(--light-gray);
    -webkit-column-count: 2;
    -webkit-column-gap: 200px;
    -webkit-column-rule: 1px solid var(--light-gray);
  }
}
