.database-page .page-header {
  margin-bottom: 30px;
}

.database-subheader-container {
  padding-bottom: 15px;
}

.database-subheader {
  text-align: center;
  padding-bottom: 15px;
}

.database-subheader-2 {
  text-align: center;
  padding-bottom: 15px;
  font-size: 22px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.survey-text, .survey-btn {
  margin: 7px;
}

.survey-btn {
  font-size: 22px;
  color: var(--dark-blue) !important;
  border-color: var(--dark-blue);
}

.survey-btn:hover, .survey-btn:focus, .survey-btn:active, .survey-btn:visited {
  background-color: var(--dark-blue);
  color: white !important;
}

.data-list-container {
  margin-bottom: 50px;
  margin-left: 50px;
  margin-right: 50px;
}

.data-list-category-section {
  margin-bottom: 40px;
  -webkit-column-break-inside: avoid;
}

.data-list-problems {
  padding-left: 25px;
}

.data-list-problem {
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}

.success-msg {
  position: absolute;
  padding-left: 5px;
  font-style: italic;
  color: var(--light-gray);
  width: 200px;
}

.toggle-problem:hover {
  cursor: pointer;
  color: var(--dark-gray);
}

.data-list-problem a {
  color: var(--gray);
}

.data-list-problem a:hover, .data-list-problem a:focus {
  color: var(--dark-gray);
  text-decoration: none;
}

.data-list-subcategory {
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 15px;
  padding-left: 5px;
}

@media all and (min-width: 700px) {
  .data-list-container {
    column-count: 2;
    column-gap: 200px;
    column-rule: 1px solid var(--light-gray);
    -webkit-column-count: 2;
    -webkit-column-gap: 200px;
    -webkit-column-rule: 1px solid var(--light-gray);
  }
}

@media all and (max-width: 480px) {
  .database-subheader-2, .survey-btn {
    font-size: 18px;
  }
}
