.contact-page {
  display: flex;
  justify-content: center;
}

.contact-page h1 {
  margin-top: 0px;
  text-align: center;
}

.contact-note-container {
  display: flex;
  justify-content: center;
}

.contact-note {
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin-bottom: 0px;
}

.contact-form {
  margin-top: 30px;
  width: 100%;
}

@media all and (min-width: 480px) {
  .contact-note {
    width: 400px;
    font-size: 22px;
  }

  .contact-form {
    margin-left: auto;
    margin-right: auto;
    width: 420px;
  }
}
