.intro-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 20px;
}

.intro ol {
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 40px;
  padding-left: 22px;
}

.intro li {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  max-width: 700px;
}

.intro li p {
  font-weight: normal;
  margin-left: 8px;
}

@media all and (min-width: 481px) {
  .intro {
    font-size: 22px;
  }  
}
