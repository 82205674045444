.blog-post {
  text-align: center;
  width: 80vw;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
}

.blog-post hr {
  border-top-color: var(--light-gray);
}

.blog-post-img {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
}

.blog-post-content {
  text-align: left;
  padding-bottom: 20px;
  line-height: 1.7;
}

.read-more {
  padding-bottom: 20px;
}

.read-more p {
  color: #337ab7;
}

.read-more p:hover {
  cursor: pointer;
  font-weight: bold;
}

.mailchimp {
  text-align: center;
}

.subscribe-header {
  margin-bottom: 25px;
}

.subscribe-input-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.subscribe-input-container .email {
  width: 250px;
}

.subscribe-input-container input {
  margin: 10px;
}

.subscribe-input-container .button {
  color: var(--dark-blue) !important;
  border-color: var(--dark-blue);
}
  
.subscribe-input-container .button:hover, .subscribe-input-container .button:focus,
.subscribe-input-container .button:active, .subscribe-input-container .button:visited {
  background-color: var(--dark-blue);
  color: white !important;
}

@media all and (max-width: 480px) {
  .blog-post {
    padding-bottom: 15px;
  }

  .blog-post-content {
    padding-bottom: 20px;
  }
}
