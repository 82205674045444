.about-column {
  width: 40%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 20px;
}

.about-image {
  width: 100%;
}

.about-header-mobile {
  text-align: center;
  margin-bottom: 15px;
}

.about-paragraph-mobile {
  margin-top: 15px;
  margin-bottom: 45px;
}
