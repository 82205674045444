body {
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --light-gray: rgb(200, 200, 200);
  --gray: rgb(99, 99, 99);
  --dark-gray: rgb(50, 50, 50);
  --extra-light-blue: #ebeff1;
  --light-blue: #4b9adb;
  --blue: #0081eb;
  --dark-blue: #0064b6;
  --red: #fd4a4a;
  --dark-red: #e61d1d;
  color: var(--gray);
}

.welcome-container, .intro-container, .auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.welcome-container img {
  position: absolute;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.welcome * {
  color: white;
}

.background-color {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(var(--dark-blue), #74addb);
  z-index: -2;
}

.auth-modal {
  padding: 0px 20px 20px 20px;
}

.info-page {
  margin-top: 100px;
  margin-bottom: 70px;
}

.page-header {
  margin-top: 100px;
  text-align: center;
  border-bottom: solid 1px var(--light-gray);
}

.intro-header, .auth-header {
  text-align: center;
}

.welcome-header {
  margin-bottom: 30px;
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  text-shadow: -2px 2px var(--dark-gray);
}

.auth-modal-header {
  margin-top: 0px;
}

.welcome-subheader, .intro-subheader, .auth-subheader {
  text-align: center;
  margin-top: 30px;
}

.intro-subheader {
  font-size: 30px;
  color: var(--gray);
}

.auth-subheader {
  font-size: 22px;
}

.welcome-subheader {
  font-size: 28px;
  text-shadow: -1px 1px var(--dark-gray);
}

.welcome-subheader:hover {
  font-weight: bold;
}

.welcome-subheader .continue-arrow {
  font-size: 20px;
}

.auth-subheader {
  margin-top: 17px;
  margin-bottom: 35px;
}

.signup-button, .login-button {
  margin-bottom: 15px;
}

.container {
  max-width: 90%;
}

h1, h2, h3, h4 {
  color: var(--dark-gray);
}

.continue-container {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 60px;
}

.loader-button {
  margin-top: 30px;
}

.cards {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.add-items-link {
  text-align: center;
  margin-bottom: 50px;
}

.fa-plus, .fa-minus, .fa-pencil-alt {
  padding-right: 10px;
  font-size: 12px;
}

.continue-container.absolute {
  position: absolute;
  width: 100%;
  bottom: 5px;
  margin-bottom: 50px;
}

@media all and (min-width: 480px) {
  .auth-modal .auth {
    margin-top: 50px;
  }

  .signup form, .login form, .reset-password form {
    margin: 0 auto;
    min-width: 300px;
    max-width: 320px;
  }

  .welcome {
    position: absolute;
    top: 25%;
    right: 8%;
  }
}

.continue-link-container {
  width: 100%;
  text-align: center;
}

.continue-link, .back-link {
  text-align: center;
  color: var(--gray);
  margin-bottom: 5px;
  font-size: 22px;
}

.welcome-link {
  margin-top: 20px;
}

.welcome-link:hover {
  color: white;
  text-decoration: none;
}

.learn-more {
  font-size: 20px;
}

.auth-link {
  font-size: 16px;
}

.back-continue-divider {
  margin-left: 15px;
  margin-right: 15px;
  font-size: 22px;
}

.continue-link:visited, .continue-link:focus, .back-link:visited, .back-link:focus {
  text-decoration: none;
  color: var(--gray);
}

.continue-arrow {
  padding-left: 12px;
}

.back-arrow {
  padding-right: 12px;
}

.full-screen {
  min-height: 100vh;
}

input {
  color: var(--dark-gray) !important;
}

.invisible {
  visibility: hidden;
}

.spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}

.page-spinner {
  position: fixed;
  top: calc(50% - 15px) !important;
  left: calc(50% - 15px);
  font-size: 30px;
}

.save-custom-container {
  display: flex;
  justify-content: center;
}

.save-custom-button {
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: var(--dark-blue);
  border-color: white;
  color: white;
  font-weight: bold;
}

.modal-content {
  color: var(--dark-gray);
  line-height: 1.7;
}

.preview-modal-title {
  font-size: 34px;
  padding-right: 40px;
}

.preview-modal div.modal-dialog div.modal-content {
  max-width: 530px;
}

.preview-modal-body {
  padding-bottom: 20px;
  padding-right: 30px;
}

.preview-modal-body p {
  margin: 0px;
  margin-bottom: 10px;
}

.details-header {
  padding-bottom: 0px;
}

.delete-button {
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: var(--red) !important;
  border-color: white;
  color: white;
  font-weight: bold;
}

.delete-button:focus, .delete-button:visited {
  background-color: var(--dark-red) !important;
  color: white !important;
  border-color: white !important;
  outline: 0 !important;
}

.auth-button {
  background-color: var(--dark-blue);
  border-color: white;
  color: white;
  font-weight: bold;
}

.helper-modal div.modal-dialog div.modal-content {
  max-width: 530px;
}

.helper-modal-title {
  font-size: 24px;
  padding-right: 40px;
}

.helper-modal-body {
  padding-bottom: 20px;
  padding-right: 30px;
}

.helper-modal-body p {
  margin: 0px;
}

p.helper-modal-paragraph {
  margin-bottom: 15px;
}

.modal-action-link {
  margin-top: 30px !important;
  margin-bottom: 20px;
}

.error {
  color: var(--red);
}

.hidden-error {
  display: none;
}

.custom-solution {
  display: flex;
}

.custom-solution-input {
  width: 100%;
}

.delete-custom-solution {
  margin-top: 10px;
  margin-left: 20px;
}

.fa-trash {
  color: var(--gray);
  font-size: 12px;
}

.full-screen .auth {
  margin-top: 100px;
  margin-bottom: 70px;
}

@media (hover: hover) {
  .continue-link:hover, .back-link:hover {
    cursor: pointer;
    text-decoration: none;
    color: var(--dark-gray) !important;
  }

  .save-custom-button:hover, .auth-button:hover {
    background-color: var(--dark-blue) !important;
    color: white !important;
  }

  .delete-button:hover {
    background-color: var(--dark-red) !important;
    color: white;
    border-color: white;
  }

  .fa-trash:hover {
    cursor: pointer;
  }
}

@media all and (max-width: 480px) {
  body {
    font-size: 13px;
  }

  .info-page {
    margin-top: 85px;
    margin-bottom: 40px;
  }
  
  .auth {
    width: 100%;
  }

  h1 {
    font-size: 24px;
  }

  .continue-link, .save-link, .back-link, .back-continue-divider {
    font-size: 18px;
  }

  .auth-link {
    font-size: 13px;
  }

  .preview-modal-title {
    font-size: 24px;
  }

  .welcome {
    margin-bottom: 200px;
  }

  .intro-header, .auth-header {
    font-size: 24px;
  }

  .welcome-header {
    font-size: 36px;
  }

  .intro-subheader {
    font-size: 22px;
  }

  .auth-subheader {
    font-size: 18px;
  }

  .welcome-subheader {
    font-size: 24px;
  }

  .welcome-subheader {
    font-weight: bold;
  }

  .page-header {
    margin-top: 75px;
  }

  .fa-plus, .fa-minus, .fa-pencil-alt, .fa-trash {
    font-size: 10px;
  }

  .delete-custom-solution {
    margin-top: 5px;
  }
}
